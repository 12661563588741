@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fafafa;

  font-family: "Roboto", sans-serif;
}

p {
  margin-bottom: 2rem;
}

.content a {
  border-bottom: dotted 1px;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
}

.content a:hover {
  @apply text-blue-300;
  border-color: #ffffff00;
}

.btn-slate {
  @apply bg-transparent;
  @apply hover:bg-slate-500;
  @apply text-slate-700;
  @apply font-semibold;
  @apply hover:text-white;
  @apply py-1;
  @apply px-2;
  @apply md:py-2;
  @apply md:px-4;
  @apply border;
  @apply border-slate-500;
  @apply hover:border-transparent;
  @apply my-auto;
  @apply transition-colors;
  @apply text-sm;
  @apply font-normal;
}
